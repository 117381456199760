import styled from 'styled-components'

type VideoContainerProps = {
  isMobile: boolean
}

export const VideoContainer = styled.div<VideoContainerProps>`
  width: ${({ isMobile }) => (isMobile ? '90%' : '100%')};
  height: null;
  position: relative;
  padding-top: 56.25%;
  margin-top: ${({ isMobile }) => (isMobile ? '1rem' : '0')};
  margin-bottom: 1rem;
  border-radius: 1rem;
  overflow: hidden;
`

export const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

import React, { FC, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import {
  CarouselDashCards,
  CarouselAdaptiveQuiz,
  CarouselMockExam,
  CarouselExamAnalytics,
} from 'assets'

import './styles.scss'

const BenefitsCarousel: FC = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 2500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      centeredSlides={true}
      modules={[Autoplay, Pagination]}
      className="feature-carousel"
    >
      <SwiperSlide className="carousel-slide">
        <img src={CarouselDashCards} alt="" className="slide-image" />
        <span className="slide-caption"></span>
      </SwiperSlide>
      <SwiperSlide className="carousel-slide">
        <img src={CarouselAdaptiveQuiz} alt="" className="slide-image" />
        <span className="slide-caption"></span>
      </SwiperSlide>
      <SwiperSlide className="carousel-slide">
        <img src={CarouselMockExam} alt="" className="slide-image" />
        <span className="slide-caption"></span>
      </SwiperSlide>
      <SwiperSlide className="carousel-slide">
        <img src={CarouselExamAnalytics} alt="" className="slide-image" />
        <span className="slide-caption"></span>
      </SwiperSlide>
    </Swiper>
  )
}
export default BenefitsCarousel

import { InputProps } from 'formik-antd'
import { PropsWithChildren } from 'react'

import * as S from './styles'

type IInputProps = PropsWithChildren<InputProps>

const FormInput = (props: IInputProps) => {
  return <S.FormInput {...props} />
}

export default FormInput

import React, { useState, useContext, useEffect } from 'react'
import { Modal } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'

import { UpgradeModal } from 'components'

type ContextProps = {
  openUpgradePrompt: () => void
  isUpgradeOpen: boolean
  closeUpgradePrompt: () => void
}

const UpgradeContext = React.createContext({} as ContextProps)

type ProviderProps = {
  children: React.ReactNode
}

export const UpgradeProvider = ({ children }: ProviderProps) => {
  const [isUpgradeOpen, setUpgradeOpen] = useState(false)

  const openUpgradePrompt = () => {
    setUpgradeOpen(true)
  }

  const closeUpgradePrompt = () => {
    setUpgradeOpen(false)
  }

  return (
    <UpgradeContext.Provider
      value={{
        openUpgradePrompt,
        isUpgradeOpen,
        closeUpgradePrompt,
      }}
    >
      {children}
      <Modal
        className="upgrade-prompt-modal"
        visible={isUpgradeOpen}
        centered
        onCancel={closeUpgradePrompt}
        width={1000}
        footer={null}
        maskClosable={false}
        destroyOnClose
        closeIcon={<CloseCircleFilled />}
      >
        <UpgradeModal />
      </Modal>
    </UpgradeContext.Provider>
  )
}

export const useUpgradeContext = () => useContext(UpgradeContext)

import { setUserId, setUserProperties } from 'firebase/analytics'

import {
  convertObjectKeysToSnakeCase,
  stringifyObjectProperties,
} from '../helpers/utility-helper'
import { analytics } from '../services/FirebaseService'

export type UserTraits = {
  email?: string | null
  name?: string | null
  isSubscribed?: boolean
  appInstalled?: boolean
  quizStarted?: boolean
  quizStartedAt?: number
  cardsStarted?: boolean
  cardsStartedAt?: number
  cfaPeriod?: string
}

const identify = (userId: string, userTraits: UserTraits) => {
  const snakeCasedUserTraits = convertObjectKeysToSnakeCase(userTraits)
  identifyFA(userId, snakeCasedUserTraits)
  return window.analytics.identify(userId, snakeCasedUserTraits)
}

const identifyFA = async (userId: string, userTraits: any) => {
  await setUserId(analytics, userId)
  await setUserProperties(analytics, stringifyObjectProperties(userTraits))
}

const track = (eventName: string, eventProps?: any) => {
  return window.analytics.track(eventName, eventProps)
}

const page = (pageName: string, pageProps?: any) => {
  return window.analytics.page(pageName, pageProps)
}

const reset = () => {
  return window.analytics.reset()
}

export default {
  identify,
  track,
  page,
  reset,
}

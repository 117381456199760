import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { ACCESS_TOKEN_STORAGE_KEY } from './constants-helper'
import { getStorageItem, setStorageItem } from './storage-helper'
import { ICachedCmsModules, IFlatCmsModules } from '../types/Content'

type Module = {
  id: string
  title: string
}

type Lesson = {
  id: string
  title: string
  module?: string
}

type Reading = {
  id: string
  title: string
  module?: string
}

const getAccessToken = () => {
  return getStorageItem(ACCESS_TOKEN_STORAGE_KEY)
}

const setAccessToken = (token: string) => {
  return setStorageItem(ACCESS_TOKEN_STORAGE_KEY, token)
}

const getModuleInitials = (moduleName: string) => {
  const MODULE_MAP: any = {
    'Ethical & Professional Standards': 'ES',
    'Quantitative Methods': 'QM',
    Economics: 'ECO',
    'Financial Reporting & Analysis': 'FRA',
    'Corporate Finance': 'CF',
    'Equity Investments': 'EQ',
    'Fixed Income': 'FI',
    Derivatives: 'DS',
    'Alternative Investments': 'AI',
    'Portfolio Management': 'PM',
    Unattempted: 'UA',
  }

  return (
    MODULE_MAP[moduleName] ||
    moduleName
      .split(' ')
      .map((x) => x.substring(0, 1))
      .join('')
      .toUpperCase()
  )
}

const flattenCmsModules = (cmsModules: ICachedCmsModules) => {
  let flattenedModules = [] as IFlatCmsModules
  cmsModules.forEach(({ lessons, ...restModule }) => {
    flattenedModules.push({
      ...restModule,
      type: 'module',
    })
    lessons.forEach(({ readings, ...restLesson }) => {
      flattenedModules.push({
        ...restLesson,
        module: restModule.id,
        type: 'lesson',
      })
      readings.forEach((reading) => {
        flattenedModules.push({
          ...reading,
          lesson: restLesson.id,
          module: restModule.id,
          type: 'reading',
        })
      })
    })
  })
  return flattenedModules
}

const getGroupedModules = (cmsModules: ICachedCmsModules) => {
  const flattenedModules = flattenCmsModules(cmsModules)
  const [modules, lessons, readings]: [Module[], Lesson[], Reading[]] = [
    [],
    [],
    [],
  ]
  flattenedModules.forEach((item) => {
    if (item.type === 'module') {
      modules.push({
        id: item.id,
        title: item.title,
      })
    }
    if (item.type === 'lesson') {
      lessons.push({
        id: item.id,
        title: item.title,
        module: item.module,
      })
    }
    if (item.type === 'reading') {
      readings.push({
        id: item.id,
        title: item.title,
        module: item.module,
      })
    }
  })
  return { modules, lessons, readings }
}

const getCurrentWeek = () => {
  // dayjs().startOf('week') starts from Sunday
  // use isoWeek to start the week from Monday
  const start = dayjs().startOf('week')
  const end = dayjs().endOf('week')

  let currentDate = start
  let ranges = []

  while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    currentDate = currentDate.add(1, 'day')
    ranges.push(currentDate.format())
  }
  return ranges
}

const getUserTimezone = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  return dayjs.tz.guess()
}

export const stringifyObjectProperties = (mixedObject: any) => {
  if (!mixedObject) return {}

  return Object.keys(mixedObject).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: String(mixedObject[curr]),
    }),
    {},
  )
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const convertObjectKeysToSnakeCase = (camelCaseObject: any) => {
  if (!camelCaseObject) return {}

  return Object.keys(camelCaseObject).reduce(
    (prev, curr) => ({
      ...prev,
      // @ts-ignore
      [camelToSnakeCase(curr)]: camelCaseObject[curr],
    }),
    {},
  )
}

export {
  getAccessToken,
  setAccessToken,
  getModuleInitials,
  flattenCmsModules,
  getGroupedModules,
  getCurrentWeek,
  getUserTimezone,
}

import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { Button } from 'components'

import * as S from './styles'

type CheckoutFormProps = {
  clientSecret: string
  onSuccess: () => void
}

export default function CheckoutForm({
  clientSecret,
  onSuccess,
}: CheckoutFormProps) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          // setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      //   return_url: 'http://localhost:3000',
      // },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error.message || '')
    } else if (error) {
      setMessage('An unexpected error occurred.')
    } else {
      // Handle payment success
      onSuccess()
      setMessage('Payment successful')
    }

    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button
        htmlType="submit"
        loading={isLoading}
        disabled={isLoading || !stripe || !elements}
        style={{
          width: '100%',
          marginTop: 16,
        }}
      >
        PAY NOW
      </Button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

import React, { FC } from 'react'
import Logo from '../Logo'
import { LoadingOutlined } from '@ant-design/icons'
import ThirdBackground from '../Layouts/ThirdBackground'

import './style.scss'

const Preloader: FC = () => {
  return (
    <ThirdBackground>
      <div className="preloader-large">
        <div className="loader">
          <Logo />
          <LoadingOutlined spin />
        </div>
      </div>
    </ThirdBackground>
  )
}

export default Preloader

import styled from 'styled-components'
import { Input as AntInput } from 'antd'

export const Input = styled(AntInput)`
  border-radius: 10px;
  font-family: var(--font-family-header);
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #ffffff;
  background-color: #f9fafb;
`

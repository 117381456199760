import axios, { AxiosError, AxiosInstance } from 'axios'
import { BASE_API_URL } from '../helpers/environment-helper'
import cookies from 'js-cookie'
import * as rax from 'retry-axios'
import * as qs from 'qs'

import { auth } from './FirebaseService'
import { UserPerformanceStat } from 'types/User'

class UserService {
  private api: AxiosInstance = axios.create({
    baseURL: BASE_API_URL,
  })

  constructor() {
    this.api.interceptors.request.use(function (config) {
      const token = cookies.get('xxid_pca')
      config.headers.Authorization = token || ''
      return config
    })
    this.api.defaults.raxConfig = {
      instance: this.api,
      retry: 3,
      retryDelay: 50,
      httpMethodsToRetry: ['GET', 'DELETE', 'PUT', 'POST'],
      statusCodesToRetry: [
        // [500, 599],
        [400, 401],
      ],
      onRetryAttempt: async (err) => {
        const token = await auth.currentUser?.getIdToken(true)
        cookies.set('xxid_pca', token || '')
      },
    }
    rax.attach(this.api)
  }

  public async getUser(): Promise<IUser> {
    const response = await this.api.request<IUser>({
      url: '/user/fetch-user',
      method: 'get',
    })

    return response.data
  }

  public async createUser(
    uid: string,
    email: string,
    name: string | null,
  ): Promise<IUser> {
    let dataToSend: {
      uid: string
      email: string
      profile?: { name?: string }
    } = {
      email,
      uid,
      profile: {
        name: name || 'CFA Aspirant',
      },
    }

    const response = await this.api.request<IUser>({
      url: '/user',
      method: 'post',
      data: dataToSend,
    })

    return response.data
  }

  public async verifyEmail(email: string): Promise<IUser> {
    const response = await this.api.request<IUser>({
      url: '/user/verify-email',
      method: 'put',
      data: { email: email },
    })

    return response.data
  }

  public async updatePlaylistQuestions(
    email: string,
    questionsQuantity: IPlaylistQuestions,
  ): Promise<IUser> {
    const response = await this.api.request<IUser>({
      url: '/user/playlist-questions',
      method: 'put',
      data: { email: email, questionsQuantity: questionsQuantity },
    })

    return response.data
  }

  public async finishOnboarding(): Promise<IUser> {
    const response = await this.api.request<IUser>({
      url: '/user/skip-onboarding',
      method: 'put',
    })

    return response.data
  }

  public async updateProfile(profile: IProfile): Promise<IUser> {
    const response = await this.api.request<IUser>({
      url: '/user/update-profile',
      method: 'put',
      data: { profile },
    })

    return response.data
  }

  public async getProfile(): Promise<IProfile> {
    const response = await this.api.request<IProfile>({
      url: '/user/get-profile',
      method: 'post',
    })

    return response.data
  }

  public async getProgress(email: string): Promise<IProgress> {
    const response = await this.api.request<IProgress>({
      url: '/user/get-progress',
      method: 'post',
      data: { email },
    })

    return response.data
  }

  public async updateLibraryProgress(
    progress: any,
  ): Promise<ILibraryProgress[]> {
    const response = await this.api.request<ILibraryProgress[]>({
      url: '/user/update-library-progress',
      method: 'post',
      data: {
        ...progress,
      },
    })

    return response.data
  }

  public async updatePlaylistQuizProgress(
    playlistId: string,
    quizType: 'initialQuiz' | 'finalQuiz',
    quizResult: {
      selectedOptions: number[]
      score: number
    },
  ): Promise<IPlaylistProgress> {
    const response = await this.api.request<IPlaylistProgress>({
      url: '/user/update-playlist-quiz-progress',
      method: 'post',
      data: {
        playlistId,
        quizType,
        quizResult,
      },
    })

    return response.data
  }

  public async getLibraryProgress(): Promise<ILibraryProgress[]> {
    const response = await this.api.request<ILibraryProgress[]>({
      url: '/user/get-library-progress',
      method: 'get',
    })

    return response.data
  }

  public async getPlaylistProgress(
    playlistId: string,
  ): Promise<IPlaylistProgress> {
    const query = qs.stringify({
      id: playlistId,
    })

    const response = await this.api.request<IPlaylistProgress>({
      url: `/user/get-playlist-progress?${query}`,
      method: 'get',
    })

    return response.data
  }

  public async updateProgress(
    email: string,
    progress: IProgress,
  ): Promise<IUser> {
    const response = await this.api.request<IUser>({
      url: '/user/update-progress',
      method: 'put',
      data: { email, progress },
    })

    return response.data
  }

  public async getNotes(): Promise<INote[]> {
    const response = await this.api.request<INote[]>({
      url: '/user/get-notes',
      method: 'get',
    })

    return response.data
  }

  public async addNote(note: INote): Promise<INote> {
    const response = await this.api.request<INote>({
      url: '/user/add-note',
      method: 'post',
      data: { note },
    })

    return response.data
  }

  public async deleteNote(noteId: string): Promise<INote> {
    const response = await this.api.request<INote>({
      url: '/user/remove-note',
      method: 'post',
      data: { noteId },
    })

    return response.data
  }

  public async getCheckSubscription(email?: string): Promise<any> {
    const response = await this.api.request({
      url: '/user/fetch-subscription',
      method: 'get',
    })
    return response.data
  }

  public async createSubscriptionOrder(isRazor: number): Promise<any> {
    const response = await this.api.request<string[]>({
      url: '/user/create-subscription-order',
      method: 'get',
      params: {
        isRazor,
      },
    })
    return response.data
  }

  public async createSubscription(subscriptionData: any): Promise<any> {
    const response = await this.api.request<string[]>({
      url: '/user/create-web-subscription',
      method: 'post',
      data: { subscriptionData },
    })
    return response.data
  }

  public async createStripeCustomer(address: IBillingAddress): Promise<any> {
    const response = await this.api.request<string[]>({
      url: '/user/create-stripe-customer',
      method: 'post',
      data: { address },
    })
    return response.data
  }

  public async saveExamData(exam: IExam) {
    const response = await this.api.request<string[]>({
      url: '/user/end-exam',
      method: 'post',
      data: { exam },
    })

    return response.data
  }

  public async getQuizStats() {
    const response = await this.api.request<IExam[]>({
      url: '/user/exams',
      method: 'post',
    })
    return response.data
  }

  public async getProgressStats(): Promise<UserPerformanceStat> {
    const response = await this.api.request({
      url: '/user/progress-overview',
      method: 'post',
    })
    return response.data
  }

  public async addReport(email: string, videoId: string, issue: string) {
    const response = await this.api.request<any>({
      url: '/user/video-report',
      method: 'post',
      data: { email, videoId, issue },
    })
    return response.data
  }

  public async postGuidedQuizStats(questionData: any, isTimed?: boolean) {
    const response = await this.api.request<any>({
      url: '/user/guided-quiz-stats',
      method: 'post',
      data: { questionData, isTimed },
    })
    return response.data
  }

  public async getGuidedQuizQuestionSet(
    readingIds: string[],
    maxNumberOfQues: number,
  ) {
    const response = await this.api.request<any>({
      url: '/user/get-guided-quiz-question-set',
      method: 'post',
      data: { readingIds, maxNumberOfQues },
    })
    return response.data
  }

  public async getGuidedQuizStats() {
    const response = await this.api.request<any>({
      url: '/user/get-guided-quiz-stats',
      method: 'post',
    })
    return response.data
  }

  public async postRecallCardStats(cardData: any) {
    const response = await this.api.request<any>({
      url: '/user/recall-card-stats',
      method: 'post',
      data: { cardData },
    })
    return response.data
  }

  public async getActiveRecallCardDeck(
    readingIds: string[],
    maxNumberOfCards: number,
  ) {
    const response = await this.api.request<any>({
      url: '/user/get-active-recall-card-deck',
      method: 'post',
      data: { readingIds, maxNumberOfCards },
    })
    return response.data
  }

  public async getRecallCardStats() {
    const response = await this.api.request<any>({
      url: '/user/get-recall-card-stats',
      method: 'get',
    })
    return response.data
  }

  public async updateReadingProgress(email: string, readProgress: any) {
    const response = await this.api.request({
      url: '/user/reading-progress',
      method: 'post',
      data: { email, readProgress },
    })
    return response.data
  }
  public async getReadingProgress(email: string) {
    const response = await this.api.request({
      url: '/user/get-reading-progress',
      method: 'post',
      data: { email },
    })
    return response.data
  }
  public async updateLessonProgress(email: string, lessProgress: any) {
    const response = await this.api.request({
      url: '/user/lesson-progress',
      method: 'post',
      data: { email, lessProgress },
    })
    return response.data
  }
  public async getLessonProgress(email: string) {
    const response = await this.api.request({
      url: '/user/get-lesson-progress',
      method: 'post',
      data: { email },
    })
    return response.data
  }

  public async submitFeedback(feedback: IFeedback) {
    const response = await this.api.request<IUser>({
      url: '/user/submit-feedback',
      method: 'post',
      data: { feedback },
    })
    return response.data
  }

  public async toggleBookmark(bookmark: IBookmark) {
    const response = await this.api.request({
      url: '/user/toggle-bookmark',
      method: 'post',
      data: { bookmark },
    })

    return response.data
  }

  public async getBookmarks({
    moduleId,
    readingId,
    contentType,
  }: {
    moduleId?: string
    readingId?: string
    contentType?: string
  }): Promise<IBookmark[]> {
    const query = qs.stringify({
      moduleId,
      readingId,
      contentType,
    })

    const response = await this.api.request({
      url: `/user/get-bookmarks?${query}`,
      method: 'get',
    })
    return response.data
  }

  public async contactSupport(email: string, message: string) {
    const response = await this.api.request({
      url: '/user/contact-support',
      method: 'post',
      data: { email, message },
    })

    return response.data
  }

  public async getCachedModules() {
    const response = await this.api.request<any>({
      url: '/content/cached-modules',
      method: 'get',
    })
    return response.data
  }

  public async updateLastSeenPlatform(platform: string): Promise<IUser> {
    const response = await this.api.request<IUser>({
      url: '/user/update-platform',
      method: 'post',
      data: { platform },
    })

    return response.data
  }

  public async fetchCOTD() {
    const response = await this.api.request<any>({
      url: '/user/fetch-cotd',
      method: 'get',
    })

    return response.data
  }

  public async upgradeUser(email: string) {
    try {
      const response = await this.api.request<any>({
        url: '/user/upgrade-user',
        method: 'post',
        data: {
          email,
        },
      })
      return response.data
    } catch (error: any) {
      throw new Error(error)
    }
  }
}
const instance = new UserService()

export default instance

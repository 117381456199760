import styled from 'styled-components'
import { Button as AntButton } from 'antd'

export const Button = styled(AntButton)`
  background: var(--button-color);
  color: #fff;
  border-color: transparent;
  line-height: 1;
  border-radius: 8px;
  font-weight: 700;
  font-size: 1.12rem;
  letter-spacing: 1px;
  height: 3rem;
  text-transform: uppercase;
  &:hover,
  &:focus {
    background: var(--button-color-hover);
    border-color: transparent;
    color: #fff;
  }
`

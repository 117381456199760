import { createContext } from 'react'
import { makeObservable, action, observable } from 'mobx'

class UserStore {
  @observable profile: IProfile = {
    name: 'CFA Candidate',
  }
  @observable plansActive = []
  @observable subscription: { isActive: boolean } | SubscriptionType | null =
    null
  @observable ShowModel = false
  @observable InBookmark = false
  @observable account: IAccount = {
    isFirstTime: false,
    isVerified: true,
    isSubscribed: false,
    playlistQuestions: 10,
    promoCode: '',
  }
  @observable authenticated = false
  @observable email = ''
  @observable uid = ''
  @observable freeTrial = false
  @observable attemptedExams: IExam[] = []

  constructor() {
    makeObservable(this)
  }

  @action
  setShowModel(status: boolean) {
    this.ShowModel = status
  }

  @action
  setProfile(profile: IProfile) {
    this.profile = profile
  }

  @action
  setAccount(account: IAccount) {
    this.account = account
  }

  @action
  setAttemptedExams(exams: IExam[]) {
    this.attemptedExams = exams
  }

  clearAll() {
    this.profile = {}
    this.account = {
      isFirstTime: false,
      isVerified: true,
      isSubscribed: false,
      playlistQuestions: 10,
      promoCode: '',
    }
    this.authenticated = false
    this.email = ''
  }
}

export const UserStoreContext = createContext(new UserStore())

import Lottie from 'lottie-web'
import React, { FC, useEffect, useRef } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import Logo from '../Logo'
import { LottieLoader } from 'assets'
import './style.scss'

const AppPreloader: FC = () => {
  return (
    <div className="preloader">
      <div className="loader">
        <Logo />
        <LoadingOutlined spin />
      </div>
    </div>
  )
}

const AppLoader = () => {
  const anime = useRef(null)
  useEffect(() => {
    Lottie.loadAnimation({
      // @ts-ignore
      container: anime.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: LottieLoader,
    })
    return () => Lottie.stop()
  }, [])
  return <div ref={anime} style={{ height: '40vh' }}></div>
}

export default AppPreloader

import React from 'react'
import { AppleOutlined } from '@ant-design/icons'
import useAuth from 'hooks/useAuth'

import * as S from './styles'

const AppleSignin = ({
  isSignUp,
  onAuthError,
}: {
  isSignUp?: boolean
  onAuthError?: (errMessage: string) => void
}) => {
  const { signinWithApple, signupWithApple } = useAuth()
  const startAppleSignin = async () => {
    try {
      isSignUp ? await signupWithApple() : await signinWithApple()
    } catch (error) {
      if (onAuthError) onAuthError(error.message)
    }
  }

  return (
    <S.AppleSigninButton
      icon={<AppleOutlined />}
      size="large"
      onClick={startAppleSignin}
    >
      {`${isSignUp ? 'Sign Up' : 'Sign In'} with Apple`}
    </S.AppleSigninButton>
  )
}

export default AppleSignin

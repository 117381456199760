export const AppIcon = require('./icons/mastercfa-icon.png')
export const AppLogo = require('./icons/mastercfa-full-logo.png')
export const PlayStoreLogo = require('./images/play-store.png')
export const AppStoreLogo = require('./images/app-store.png')
export const UpgradeGraphic = require('./images/upgrade-graphic.png')
export const CarouselDashCards = require('./images/carousel-dash-cards.png')
export const CarouselAdaptiveQuiz = require('./images/carousel-adaptive-quiz.png')
export const CarouselMockExam = require('./images/carousel-mock-exam.png')
export const CarouselExamAnalytics = require('./images/carousel-exam-analytics.png')

export const AnimIconAdaptiveQuiz = require('./images/anim-icon-adaptive-quiz.gif')
export const AnimIconMockExam = require('./images/anim-icon-mock-exam.gif')
export const AnimIconCOTD = require('./images/anim-icon-cotd.gif')
export const AnimIconDashCards = require('./images/anim-icon-dash-cards.gif')
export const AnimIconCourseLibrary = require('./images/anim-icon-course-library.gif')
export const AnimIconProTip = require('./images/anim-icon-pro-tip.gif')
export const AnimIconCalendar = require('./images/anim-icon-calendar.gif')

export const StatIconAdaptiveQuiz = require('./images/stat-icon-adaptive-quiz.png')
export const StatIconMockExam = require('./images/stat-icon-mock-exam.png')
export const StatIconCOTD = require('./images/stat-icon-cotd.png')
export const StatIconDashCards = require('./images/stat-icon-dash-cards.png')
export const StatIconCourseLibrary = require('./images/stat-icon-course-library.png')
export const StatIconProTip = require('./images/stat-icon-pro-tip.png')
export const StatIconCalendar = require('./images/stat-icon-calendar.png')

export const AnimIntroBuilder = require('./images/Builder.gif')
export const AnimIntroDashPlayer = require('./images/Dash.gif')
export const AnimIntroQuizPlayer = require('./images/Play.gif')
export const AnimIntroResult = require('./images/Result.gif')

export const AppDownloadQR = require('./images/AppDownloadQR.png')

export const PreMockMcfaFull = require('./images/pre-mock-mcfa-full.png')
export const PreMockMcfaTimer = require('./images/pre-mock-mcfa-timer.png')
export const PreMockMcfaActions = require('./images/pre-mock-mcfa-actions.png')
export const PreMockMcfaAttempt = require('./images/pre-mock-mcfa-attempt.png')
export const PreMockMcfaQuestions = require('./images/pre-mock-mcfa-questions.png')

export const PreMockProFull = require('./images/pre-mock-pro-full.png')
export const PreMockProTimer = require('./images/pre-mock-pro-timer.png')
export const PreMockProActions = require('./images/pre-mock-pro-actions.png')
export const PreMockProAttempt = require('./images/pre-mock-pro-attempt.png')
export const PreMockProQuestions = require('./images/pre-mock-pro-questions.png')

export const LottieHello = require('./lotties/63820-hello.json')
export const LottieLoader = require('./lotties/25200-student.json')
export const LottiePostUpgrade = require('./lotties/42226-winning-leadership.json')

import { FC } from 'react'

import './styles.scss'

type TextCarouselProps = {
  preCarouselText?: string
  carouselItems?: string[]
}

const defaultTextCarouselProps: TextCarouselProps = {
  preCarouselText: 'Get started with',
  carouselItems: [
    'Active Recall',
    'Guided Exams',
    'Full Mock Exams',
    'Exam Analytics',
  ],
}

const TextCarousel: FC<TextCarouselProps> = ({
  preCarouselText = defaultTextCarouselProps.preCarouselText,
  carouselItems = defaultTextCarouselProps.carouselItems,
}) => {
  return (
    <div className="intro-slider-text">
      <div className="intro-carousel">
        <div className="pre-carousel">{preCarouselText}</div>
        <div className="carousel-outer">
          <div className="carousel-inner">
            {carouselItems &&
              carouselItems.map((item, idx) => (
                <div key={idx} className="carousel-element">
                  {item}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextCarousel

import { initializeApp, FirebaseOptions } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFB,
  signInWithRedirect,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail as sendPasswordResetEmailFB,
  verifyPasswordResetCode as verifyPasswordResetCodeFB,
  confirmPasswordReset as confirmPasswordResetFB,
  signOut,
} from 'firebase/auth'

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCk-uixpdf-YOoXhu5vDnZpIgHYoDL4zV8',
  authDomain: 'passcfa-7a769.firebaseapp.com',
  databaseURL: 'https://passcfa-7a769.firebaseio.com',
  projectId: 'passcfa-7a769',
  storageBucket: 'passcfa-7a769.appspot.com',
  messagingSenderId: '990141970253',
  appId: '1:990141970253:web:0aebda8f89fb60d9c42904',
  measurementId: 'G-CYXP0JVDR0',
}

const firebaseApp = initializeApp(firebaseConfig)

export const auth = getAuth(firebaseApp)
export const analytics = getAnalytics(firebaseApp)
const googleProvider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider()
const appleProvider = new OAuthProvider('apple.com')

export const signUpWithEmailAndPassword = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password)
}

export const signInWithEmailAndPassword = (email: string, password: string) => {
  return signInWithEmailAndPasswordFB(auth, email, password)
}

export const googleSignin = (isInAppBrowser: boolean = false) => {
  return isInAppBrowser
    ? signInWithRedirect(auth, googleProvider)
    : signInWithPopup(auth, googleProvider)
}

export const facebookSignin = () => {
  return signInWithPopup(auth, facebookProvider)
}

export const appleSignin = () => {
  appleProvider.addScope('email')
  appleProvider.addScope('name')

  return signInWithPopup(auth, appleProvider)
}

export const sendPasswordResetEmail = (email: string) => {
  return sendPasswordResetEmailFB(auth, email)
}

export const verifyPasswordResetCode = (code: string) => {
  return verifyPasswordResetCodeFB(auth, code)
}

export const confirmPasswordReset = (code: string, password: string) => {
  return confirmPasswordResetFB(auth, code, password)
}

export const logout = () => {
  return signOut(auth)
}

import { TypographyProps } from 'antd'
import { PropsWithChildren } from 'react'

import * as S from './styles'

type ITextProps = PropsWithChildren<TypographyProps['Text']['defaultProps']>

const Text = ({ children, ...props }: ITextProps) => {
  return <S.Text {...props}>{children}</S.Text>
}

export default Text

import React from 'react'
import { GoogleOutlined } from '@ant-design/icons'
import useAuth from 'hooks/useAuth'

import * as S from './styles'

const GoogleSignin = ({
  isSignUp,
  onAuthError,
}: {
  isSignUp?: boolean
  onAuthError?: (errMessage: string) => void
}) => {
  const { signinWithGoogle, signupWithGoogle } = useAuth()
  const startGoogleSignin = async () => {
    try {
      isSignUp ? await signupWithGoogle() : await signinWithGoogle()
    } catch (error) {
      if (onAuthError) onAuthError(error.message)
    }
  }

  return (
    <S.GoogleSigninButton
      icon={<GoogleOutlined />}
      size="large"
      onClick={startGoogleSignin}
    >
      {`${isSignUp ? 'Sign Up' : 'Sign In'} with Google`}
    </S.GoogleSigninButton>
  )
}

export default GoogleSignin

import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'

import { PrivateRoutesProps } from './types'

const PrivateRoute: FC<PrivateRoutesProps> = (props: PrivateRoutesProps) => {
  const { component: Component, authenticated, account, ...rest } = props
  if (account) {
    //check if user is authenticated
    if (!authenticated) {
      return (
        <Redirect
          to={{ pathname: '/signin', state: { next: rest.location?.pathname } }}
        />
      )
    } else {
      //check if email is Verified
      if (!account.isVerified) {
        return <Redirect to="/verify-email" />
      } else {
        // Deprecated in favor of Onboarding Modal
        // if (account.isFirstTime) {
        //   return <Redirect to="/tutorial" />
        // } else {
        return <Component {...rest} />
        // }
      }
    }
  } else {
    return (
      <Redirect
        to={{ pathname: '/signin', state: { next: rest.location?.pathname } }}
      />
    )
  }
}

export default PrivateRoute

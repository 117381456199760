import axios, { AxiosInstance, AxiosResponse, Method } from 'axios'
import cookies from 'js-cookie'
import * as rax from 'retry-axios'
import * as qs from 'qs'

import { BASE_API_URL } from './environment-helper'
import { auth } from 'services/FirebaseService'

const axiosApiInstance: AxiosInstance = axios.create({
  baseURL: BASE_API_URL,
})

axiosApiInstance.interceptors.request.use(function (config) {
  const token = cookies.get('xxid_pca')
  config.headers.Authorization = token || ''
  return config
})

axiosApiInstance.defaults.raxConfig = {
  instance: axiosApiInstance,
  retry: 3,
  retryDelay: 100,
  httpMethodsToRetry: ['GET', 'POST'],
  statusCodesToRetry: [
    // [500, 599],
    [400, 401],
  ],
  onRetryAttempt: async (err) => {
    const token = await auth.currentUser?.getIdToken(true)
    cookies.set('xxid_pca', token || '')
  },
}
rax.attach(axiosApiInstance)

const getRequest = <T>(
  url: string,
  params?: any,
): Promise<AxiosResponse<T>> => {
  return axiosApiInstance.request<T>({
    url,
    params,
    method: 'GET',
  })
}

const postRequest = <T>(url: string, data: any): Promise<AxiosResponse<T>> => {
  return axiosApiInstance.request<T>({
    url,
    data,
    method: 'POST',
  })
}

export enum QUERY_KEY_MAP {
  getUserActivity = 'getUserActivity',
  fetchCOTD = 'fetchCOTD',
  getCOTDStats = 'getCOTDStats',
  updateCOTDStats = 'updateCOTDStats',
}

const API_REQUEST_URL_MAP = {
  [QUERY_KEY_MAP.getUserActivity]: '/user/get-user-activity',
  [QUERY_KEY_MAP.fetchCOTD]: '/user/fetch-cotd',
  [QUERY_KEY_MAP.getCOTDStats]: '/user/get-content-stats',
  [QUERY_KEY_MAP.updateCOTDStats]: '/user/update-content-stats',
}

export const getUserActivity = async () => {
  return (
    await getRequest<ActivityLogType>(
      API_REQUEST_URL_MAP[QUERY_KEY_MAP.getUserActivity],
    )
  ).data
}

// COTD

export const fetchCOTD = async () => {
  return (
    await getRequest<IFlashCard>(API_REQUEST_URL_MAP[QUERY_KEY_MAP.fetchCOTD])
  ).data
}

export const getCOTDStats = async ({
  contentId,
  context,
}: {
  contentId: string
  context: string
}) => {
  return (
    await getRequest<ContentStatType>(
      API_REQUEST_URL_MAP[QUERY_KEY_MAP.getCOTDStats],
      {
        contentId,
        context,
      },
    )
  ).data
}

export const updateCOTDStats = async ({
  contentId,
  context,
  progress,
}: {
  contentId: string
  context: string
  progress: number
}) => {
  return (
    await postRequest<ContentStatType>(
      API_REQUEST_URL_MAP[QUERY_KEY_MAP.updateCOTDStats],
      {
        contentId,
        context,
        progress,
      },
    )
  ).data
}

import styled from 'styled-components'
import { Button } from 'antd'

export const AppleSigninButton = styled(Button)`
  background: #000;
  border: none;
  border-radius: 10px;
  color: #fff;
  &:hover,
  &:focus {
    background: #111;
    color: #fff;
  }
`

import React, { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { PublicRoutesProps } from './types'

const PublicRoute: FC<PublicRoutesProps> = (props: PublicRoutesProps) => {
  const { component: Component, authenticated, ...rest } = props

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        // @ts-ignore
        const nextRoute = routeProps.location.state?.next || '/'
        return authenticated ? (
          <Redirect
            to={{
              // @ts-ignore
              pathname: nextRoute,
              state: { from: routeProps.location },
            }}
          />
        ) : (
          <Component {...routeProps} />
        )
      }}
    />
  )
}

export default PublicRoute

import React, { FC } from 'react'

import { BackgroundProps } from '../types'
import { Layout, Row, Col } from 'antd'
import Logo from '../../Logo'

import './style.scss'

const { Header, Content } = Layout

const AuthLayout: FC<BackgroundProps> = (props: BackgroundProps) => {
  return (
    <div className="first-background">
      <div className="green-oval"></div>
      <div className="blue-oval"></div>
      <div className="yellow-oval"></div>
      <Layout className="auth main-wrapper">
        {props.logo && (
          <Header>
            <Row>
              <Col
                className="auth-logo-wrapper"
                span={8}
                lg={{ span: 6, offset: 2 }}
                xs={{ span: 24, offset: 2 }}
              >
                <Logo big />
              </Col>
            </Row>
          </Header>
        )}
        <Content>{props.children}</Content>
      </Layout>
    </div>
  )
}

export default AuthLayout

import styled from 'styled-components'
import { CheckCircleFilled } from '@ant-design/icons'

import { Button as AntButton } from 'antd'

export const UpgradeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;
`

export const UpgradeColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ant-collapse-header-text {
    width: 100%;
  }
  .reading-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .panel-title {
      font-weight: 600;
      font-size: 16px;
      width: 30%;
      color: #828282;
    }
  }
`

export const UpgradeTitle = styled.h1`
  font-size: 1.5rem;
`

export const UpgradePriceFull = styled.h1`
  font-size: 3rem;
  color: rgb(160 57 69 / 70%);
  text-decoration: line-through;
  margin-bottom: 0;
`

export const UpgradePriceDiscount = styled.h2`
  font-size: 4rem;
  color: rgb(0 184 122 / 70%);
  margin-bottom: 0;
`

export const FOMOText = styled.p`
  font-size: 1.1em;
  font-weight: 500;
  color: #666;
`

export const UpgradeGraphic = styled.img`
  width: 90%;
  margin: auto;
  margin-bottom: 2rem;
`

export const BenefitsList = styled.div`
  display: flex;
  flex-direction: column;
`

export const BenefitsIcon = styled(CheckCircleFilled)`
  margin-right: 0.5rem;
  font-size: 1.25rem;
  color: var(--button-color);
`

export const BenefitsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`

export const BenefitsText = styled.span`
  font-size: 1.12em;
  font-weight: 500;
`

export const AddresRow = styled.div`
  display: flex;
  justify-content: space-between;
  input.ant-input {
    width: 48%;
  }
`

export const PostUpgradeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`

export const Button = styled(AntButton)`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  color: #fff;
  font-size: 1.12rem;
  letter-spacing: 1px;
`

import React, { lazy, useEffect, useState, Suspense, useContext } from 'react'
import {
  Switch,
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom'
import { useObserver } from 'mobx-react-lite'
import cookies from 'js-cookie'
import { isMobile } from 'react-device-detect'
import { User, onAuthStateChanged, applyActionCode } from 'firebase/auth'
import { AnimatePresence } from 'framer-motion'

import PrivateRoute from './Routes/PrivateRoute'
import PublicRoute from './Routes/PublicRoute'
import VerifyEmailRoute from './Routes/VerifyEmailRoute'
import TutorialRoute from './Routes/TutorialRoute'

import { UserStoreContext } from '../stores/UserStore'
import UserService from '../services/UserService'
import {
  auth,
  logout,
  verifyPasswordResetCode,
} from '../services/FirebaseService'
import Preloader from '../components/Preloader/Preloader'
import AnalyticsService from '../services/AnalyticsService'
import { ROUTE_NAME_MAP } from '../helpers/constants-helper'
import { UpgradeProvider } from '../context/UpgradeContext'

const Login = lazy(() => import('../pages/Auth/Login/Login'))
const Signup = lazy(() => import('../pages/Auth/Signup/Signup'))
const Analytics = lazy(() => import('../pages/Dashboard/Dashboard'))
const VerifyEmail = lazy(() => import('../pages/Auth/VerifyEmail/VerifyEmail'))
const ResetPassword = lazy(
  () => import('../pages/Auth/ResetPassword/ResetPassword'),
)
const ForgotPassword = lazy(
  () => import('../pages/Auth/ForgotPassword/ForgotPassword'),
)
const Tutorial = lazy(() => import('../pages/Tutorial/Tutorial'))
const ActiveRecall = lazy(() => import('../pages/ActiveRecall/ActiveRecall'))
const Calendar = lazy(() => import('../pages/Calendar/Calendar'))
const Library = lazy(() => import('../pages/Library/Library'))
const Profile = lazy(() => import('../pages/Profile/Profile'))
const Promo = lazy(() => import('../pages/Profile/Promo'))
const Home = lazy(() => import('../pages/Home/Home'))
const Shared = lazy(() => import('../pages/SharedVideo/Sharing'))
const ContentPreviewer = lazy(() => import('../pages/ContentPreviewer'))
const AdaptiveQuiz = lazy(() => import('../pages/Quiz/Guided/Guided'))
const MockExam = lazy(() => import('../pages/Quiz/Realtime/Realtime'))
const Daily = lazy(() => import('../pages/Daily'))
const Subscribe = lazy(() => import('../pages/Subscribe'))
const Mobile = lazy(() => import('../pages/Mobile'))

const App = () => {
  const UserStore = useContext(UserStoreContext)
  const [loading, setLoading] = useState(true)
  const [isVerified, setIsVerified] = useState(false)
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    //@ts-ignore
    const currentRouteName = ROUTE_NAME_MAP[location.pathname]
    if (currentRouteName) AnalyticsService.page(currentRouteName)
  }, [location.pathname])

  useEffect(() => {
    setLoading(true)

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await setAccessToken(user)
        UserStore.authenticated = true
        setLoading(false)
        try {
          const dbUser = await UserService.getUser()
          setUserStore(dbUser)
        } catch (error) {
          if (error.response.data.name === 'NotFoundError') {
            const dbUser = await UserService.createUser(
              user.uid,
              user.email || 'no-email@nomail.com',
              user.displayName,
            )
            AnalyticsService.track('User Registered', { email: user.email })
            setUserStore(dbUser)
          }
        }
      } else {
        UserStore.clearAll()
        setLoading(false)
      }
    })
  }, [UserStore])

  useEffect(() => {
    const getQuery = () => {
      return new URLSearchParams(window.location.search)
    }

    const updateUserPlatorm = () => {
      UserService.updateLastSeenPlatform('web')
    }

    if (getQuery().get('mode') === 'verifyEmail') {
      const code = getQuery().get('oobCode') || ''
      applyActionCode(auth, code)
        .then(() => {
          setIsVerified(true)
          window.location.pathname = '/'
        })
        .catch(console.log)
    }

    if (getQuery().get('mode') === 'resetPassword') {
      const code = getQuery().get('oobCode') || ''
      verifyPasswordResetCode(code)
        .then((email) => {
          history.push('/reset-password', { code, email })
        })
        .catch(console.log)
    }

    updateUserPlatorm()
  }, [])

  const setAccessToken = async (user: User) => {
    const { token, claims } = await user.getIdTokenResult(true)
    // Set isSubscribed status from Firebase
    UserStore.account.isSubscribed = Boolean(claims?.isSubscribed)
    AnalyticsService.identify(user.uid, {
      email: user.email,
      name: user.displayName,
    })
    cookies.set('xxid_pca', token)
  }

  const setUserStore = async (userMD: IUser) => {
    // In an attempt to reduce signup friction,
    // always assume the user to have verified their email
    userMD.account.isVerified = true
    UserStore.uid = userMD.uid
    UserStore.email = userMD.email
    UserStore.profile = userMD.profile
    UserStore.setAccount(userMD.account)
    UserStore.authenticated = true
    if (UserStore.account.isSubscribed) {
      UserStore.subscription = {
        isActive: UserStore.account.isSubscribed,
      }
      UserStore.subscription = await UserService.getCheckSubscription()
    }
    setLoading(false)
  }

  return useObserver(() => {
    let location = useLocation()
    return (
      <>
        {loading ? (
          <Preloader />
        ) : (
          <Suspense fallback={<Preloader />}>
            {/* <AnimatePresence exitBeforeEnter> */}
            <UpgradeProvider>
              <Switch location={location} key={location.pathname}>
                <Route
                  exact
                  path="/preview/:contentType/:id"
                  component={ContentPreviewer}
                />
                <Route
                  exact
                  path="/share/video/:moduleId/:id"
                  component={Shared}
                />
                <PublicRoute
                  exact
                  path="/signin"
                  authenticated={UserStore.authenticated}
                  component={Login}
                />
                <PublicRoute
                  exact
                  path="/signup"
                  authenticated={UserStore.authenticated}
                  component={Signup}
                />
                <PublicRoute
                  exact
                  path="/reset-password"
                  authenticated={UserStore.authenticated}
                  component={ResetPassword}
                />
                <PublicRoute
                  exact
                  path="/forgot-password"
                  authenticated={UserStore.authenticated}
                  component={ForgotPassword}
                />
                {UserStore.authenticated ? null : (
                  <Redirect
                    to={{
                      pathname: '/signup',
                      state: { next: location.pathname },
                    }}
                  />
                )}
                <PublicRoute
                  exact
                  path="/preloader"
                  authenticated={UserStore.authenticated}
                  component={Preloader}
                />
                <PrivateRoute
                  path="/active-recall"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={ActiveRecall}
                />
                <PrivateRoute
                  path="/daily-concept"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Daily}
                />
                <PrivateRoute
                  exact
                  path="/calendar"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Calendar}
                />
                <PrivateRoute
                  path="/library"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Library}
                />
                <PrivateRoute
                  path="/bookmark"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Library}
                />
                <PrivateRoute
                  exact
                  path="/profile"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Profile}
                />
                <PrivateRoute
                  exact
                  path="/subscribe"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Subscribe}
                />
                <PrivateRoute
                  exact
                  path="/activate-promo"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Promo}
                />
                <PrivateRoute
                  path="/adaptive-quiz"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={AdaptiveQuiz}
                />
                <PrivateRoute
                  path="/mock-exam"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={MockExam}
                />
                <VerifyEmailRoute
                  exact
                  path="/verify-email"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  profile={UserStore.profile}
                  email={UserStore.email}
                  component={VerifyEmail}
                />
                <TutorialRoute
                  exact
                  path="/tutorial"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Tutorial}
                />
                <PrivateRoute
                  exact
                  path="/"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Home}
                />
                <PrivateRoute
                  path="/analytics"
                  authenticated={UserStore.authenticated}
                  account={UserStore.account}
                  component={Analytics}
                />
              </Switch>
            </UpgradeProvider>
            {/* </AnimatePresence> */}
          </Suspense>
        )}
      </>
    )
  })
}

export default App

import styled from 'styled-components'
import { Button } from 'antd'

export const GoogleSigninButton = styled(Button)`
  background: #4285f4;
  border: none;
  border-radius: 10px;
  color: #eee;
  &:hover,
  &:focus {
    background: #5e97f5;
    color: #eee;
  }
`

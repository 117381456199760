import React, { useState } from 'react'
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons'

import analytics from 'services/AnalyticsService'
import UserService from 'services/UserService'
import { ANALYTICS_EVENTS } from 'helpers/constants-helper'
import * as S from './styles'

type ContentRatingProps = {
  contentId: string
  contentType: string
  ratingContext: string
}

const ContentRating = ({
  contentId,
  contentType,
  ratingContext,
}: ContentRatingProps) => {
  const [rating, setRating] = useState<1 | 5>()
  const submitRating = (rating: 1 | 5) => () => {
    setRating(rating)
    analytics.track(ANALYTICS_EVENTS.RATING_SUBMITTED, {
      context: 'COTD',
      rating,
    })
    UserService.submitFeedback({
      contentType,
      contentId,
      feedbackContext: ratingContext,
      feedbackText: `${rating}`,
    })
  }

  return (
    <S.RatingPromptContainer>
      <S.RatingPromptText>Did you learn?</S.RatingPromptText>
      <S.RatingContainer>
        <LikeOutlined
          size={36}
          onClick={submitRating(5)}
          style={{
            marginRight: 16,
            color: rating === 5 ? '#4385ff' : '#666',
          }}
        />
        <DislikeOutlined
          size={36}
          onClick={submitRating(1)}
          style={{
            color: rating === 1 ? '#4385ff' : '#666',
          }}
        />
      </S.RatingContainer>
    </S.RatingPromptContainer>
  )
}

export default ContentRating

export const staticModules = [
  {
    name: 'Ethical & Professional Standards',
    module: 1,
  },
  {
    name: 'Quantitative Methods',
    module: 2,
  },
  {
    name: 'Economics',
    module: 3,
  },
  {
    name: 'Financial Reporting & Analysis',
    module: 4,
  },
  {
    name: 'Corporate Finance',
    module: 5,
  },
  {
    name: 'Equity Investments',
    module: 6,
  },
  {
    name: 'Fixed Income',
    module: 7,
  },
  {
    name: 'Derivatives',
    module: 8,
  },
  {
    name: 'Alternative Investments',
    module: 9,
  },
  {
    name: 'Portfolio Management',
    module: 10,
  },
]

export const staticReadings = [
  {
    title: ' Ethics and Trust in the Investment Profession',
  },
  {
    title: 'Reading-2',
  },
  {
    title: ' Code of Ethics and Standards of Professional Conduct',
  },
  {
    title: ' Guidance for Standards I–VII',
  },
  {
    title:
      ' Introduction to the Global Investment Performance Standards (GIPS®)',
  },
  {
    title: ' Global Investment Performance Standards (GIPS®)',
  },
  {
    title: ' The Time Value of Money',
  },
  {
    title: ' Statistical Concepts and Market Returns',
  },
  {
    title: ' Probability Concepts',
  },
  {
    title: ' Common Probability Distributions',
  },
  {
    title: ' Sampling and Estimation',
  },
  {
    title: ' Hypothesis Testing',
  },
  {
    title: ' Topics in Demand and Supply Analysis',
  },
  {
    title: ' The Firm and Market Structures',
  },
  {
    title: ' Aggregate Output, Prices, and Economic Growth',
  },
  {
    title: ' Understanding Business Cycles',
  },
  {
    title: ' Monetary and Fiscal Policy',
  },
  {
    title: ' International Trade and Capital Flows',
  },
  {
    title: ' Currency Exchange Rates',
  },
  {
    title: ' Introduction to Financial Statement Analysis',
  },
  {
    title: ' Financial Reporting Standards',
  },
  {
    title: ' Understanding Income Statements',
  },
  {
    title: ' Understanding Balance Sheets',
  },
  {
    title: ' Understanding Cash Flow Statements',
  },
  {
    title: ' Financial Analysis Techniques',
  },
  {
    title: ' Inventories',
  },
  {
    title: ' Long-Lived Assets',
  },
  {
    title: ' Income Taxes',
  },
  {
    title: ' Non-Current (Long-Term) Liabilities',
  },
  {
    title: ' Financial Reporting Quality',
  },
  {
    title: ' Applications of Financial Statement Analysis',
  },
  {
    title: ' Introduction to Corporate Governance and Other ESG Considerations',
  },
  {
    title: ' Capital Budgeting',
  },
  {
    title: ' Cost of Capital',
  },
  {
    title: ' Measures of Leverage',
  },
  {
    title: ' Working Capital Management',
  },
  {
    title: ' Market Organization and Structure',
  },
  {
    title: ' Security Market Indexes',
  },
  {
    title: ' Market Efficiency',
  },
  {
    title: ' Overview of Equity Securities',
  },
  {
    title: ' Introduction to Industry and Company Analysis',
  },
  {
    title: ' Equity Valuation: Concepts and Basic Tools',
  },
  {
    title: ' Fixed-Income Securities: Defining Elements',
  },
  {
    title: ' Fixed-Income Markets: Issuance, Trading, and Funding',
  },
  {
    title: ' Introduction to Fixed-Income Valuation',
  },
  {
    title: ' Introduction to Asset-Backed Securities',
  },
  {
    title: ' Understanding Fixed-Income Risk and Return',
  },
  {
    title: ' Fundamentals of Credit Analysis',
  },
  {
    title: ' Derivative Markets and Instruments',
  },
  {
    title: ' Basics of Derivative Pricing and Valuation',
  },
  {
    title: ' Introduction to Alternative Investments',
  },
  {
    title: ' Portfolio Management: An Overview',
  },
  {
    title: ' Portfolio Risk and Return: Part I',
  },
  {
    title: ' Portfolio Risk and Return: Part II',
  },
  {
    title: ' Basics of Portfolio Planning and Construction',
  },
  {
    title: ' Introduction to Risk Management',
  },
  {
    title: ' Technical Analysis',
  },
  {
    title: ' Fintech in Investment Management',
  },
]

export const MODULE_INITIALS_MAP = {
  '5f52837c3047042d00b2e4f9': 'EPS',
  '5f5285f63047042d00b2e4fa': 'QM',
  '5f6478400bbd730017fce05c': 'ECO',
  '5f6478610bbd730017fce05d': 'FRA',
  '5f64786f0bbd730017fce05e': 'CF',
  '5f64787d0bbd730017fce05f': 'EI',
  '5f6478880bbd730017fce060': 'FI',
  '5f6478960bbd730017fce061': 'DS',
  '5f6478a30bbd730017fce062': 'AI',
  '5f6478af0bbd730017fce063': 'PM',
}

export const ACCESS_TOKEN_STORAGE_KEY = 'xxid'

export enum ROUTE_MAP {
  SIGNUP = '/signup',
  SIGNIN = '/signin',
  RESET_PASSWORD = '/reset-password',
  ACTIVE_RECALL_BUILDER = '/active-recall/builder',
  ACTIVE_RECALL_PLAYER = '/active-recall/player',
  ACTIVE_RECALL_RESULT = '/active-recall/result',
  ADAPTIVE_QUIZ_BUILDER = '/adaptive-quiz/builder',
  ADAPTIVE_QUIZ_PLAYER = '/adaptive-quiz/player',
  ADAPTIVE_QUIZ_RESULT = '/adaptive-quiz/result',
  LIBRARY_COURSE = '/library/course',
  LIBRARY_PLAYLIST = '/library/playlist',
  LIBRARY_CARDS = '/library/cards',
  LIBRARY_VIDEOS = '/library/videos',
  DASHBOARD = '/',
  CALENDAR = '/calendar',
  PROFILE = '/profile',
}

export const ROUTE_NAME_MAP = {
  [ROUTE_MAP.SIGNUP]: 'Signup',
  [ROUTE_MAP.SIGNIN]: 'Login',
  [ROUTE_MAP.RESET_PASSWORD]: 'Reset Password',
  [ROUTE_MAP.DASHBOARD]: 'Dashboard',
  [ROUTE_MAP.CALENDAR]: 'Calendar',
  [ROUTE_MAP.PROFILE]: 'Profile',
  [ROUTE_MAP.ACTIVE_RECALL_BUILDER]: 'ActiveRecallBuilder',
  [ROUTE_MAP.ACTIVE_RECALL_PLAYER]: 'ActiveRecallPlayer',
  [ROUTE_MAP.ACTIVE_RECALL_RESULT]: 'ActiveRecallResult',
  [ROUTE_MAP.ADAPTIVE_QUIZ_BUILDER]: 'AdaptiveQuizBuilder',
  [ROUTE_MAP.ADAPTIVE_QUIZ_PLAYER]: 'AdaptiveQuizPlayer',
  [ROUTE_MAP.ADAPTIVE_QUIZ_RESULT]: 'AdaptiveQuizResult',
  [ROUTE_MAP.LIBRARY_COURSE]: 'CourseLibrary',
  [ROUTE_MAP.LIBRARY_PLAYLIST]: 'CoursePlaylist',
  [ROUTE_MAP.LIBRARY_CARDS]: 'CourseCards',
  [ROUTE_MAP.LIBRARY_VIDEOS]: 'CourseVideos',
}

export const ANALYTICS_EVENTS = {
  USER_REGISTERED: 'User Registered',
  PURCHASE_PROMPTED: 'Purchase Prompted',
  PURCHASE_INITIATED: 'Purchase Initiated',
  PURCHASE_COMPLETED: 'Purchase Completed',
  PURCHASE_CANCELLED: 'Purchase Cancelled',
  PLAYLIST_OPENED: 'Playlist Opened',
  PLAYLIST_ITEM_OPENED: 'Playlist Step Opened',
  PLAYLIST_ITEM_FINISHED: 'Playlist Step Finished',
  QUIZ_STARTED: 'Quiz Started',
  QUIZ_FINISHED: 'Quiz Finished',
  FLASHCARDS_STARTED: 'Flashcards Started',
  FLASHCARDS_FINISHED: 'Flashcards Finished',
  USER_DELETED: 'User Deleted',
  RATING_SUBMITTED: 'Rating Submitted',
}

export const APP_STORE_URL =
  'https://apps.apple.com/my/app/mastercfa/id1609976559'
export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.mastercfa.app'

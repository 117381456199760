import { ButtonProps } from 'antd'
import { PropsWithChildren } from 'react'

import * as S from './styles'

type IButtonProps = PropsWithChildren<ButtonProps>

const Button = ({ children, ...props }: IButtonProps) => {
  return <S.Button {...props}>{children}</S.Button>
}

export default Button

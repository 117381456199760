import styled from 'styled-components'

export const RatingPromptContainer = styled.div`
  margin-top: 48px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const RatingContainer = styled.div`
  flex-direction: row;
  font-size: 36px;
`

export const RatingPromptText = styled.p`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #333;
`

export const RatingButton = styled.div`
  padding-top: 8px;
`

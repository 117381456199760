import { FormikHelpers } from 'formik'
import { User } from 'firebase/auth'
import {
  signUpWithEmailAndPassword,
  signInWithEmailAndPassword,
  googleSignin,
  sendPasswordResetEmail,
  confirmPasswordReset,
  appleSignin,
} from 'services/FirebaseService'
import Analytics from 'services/AnalyticsService'
import UserService from 'services/UserService'
import { detect } from 'detect-browser'

interface SignupFormValues {
  name: string
  email: string
  password: string
}

interface LoginFormValues {
  email: string
  password: string
}

interface ForgotPasswordFormValues {
  email: string
}

interface ResetPasswordFormValues {
  password: string
}

enum AuthEventType {
  SIGNUP = 'Signup',
  LOGIN = 'Login',
}

enum AuthEventMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  APPLE = 'apple',
}

const DEFAULT_USER_NAME = 'CFA Aspirant'

export default function useAuth() {
  const trackAuthEvent = (
    eventType: AuthEventType,
    eventMethod?: AuthEventMethod,
  ) => {
    Analytics.track(`Initiate ${eventType}`, {
      method: eventMethod,
    })
  }

  const createUser = (userId: string, email: string) => {
    return UserService.createUser(userId, email, DEFAULT_USER_NAME)
  }

  const createMCFAUser = (authUser: User | null) => {
    if (authUser?.email) return createUser(authUser?.uid, authUser?.email)
    // await authUser?.updateProfile({ displayName: values.name })
    // await authUser?.sendEmailVerification()
  }

  const signupWithEmailAndPassword = async (
    values: SignupFormValues,
    { setSubmitting }: FormikHelpers<SignupFormValues>,
    setAuthError: (errMessage: string) => void,
  ) => {
    setSubmitting(true)
    setAuthError('')
    try {
      trackAuthEvent(AuthEventType.SIGNUP, AuthEventMethod.EMAIL)
      const authUserCredential = await signUpWithEmailAndPassword(
        values.email,
        values.password,
      )
      await createMCFAUser(authUserCredential.user)
    } catch (error) {
      setAuthError(error.message)
    } finally {
      setSubmitting(false)
    }
  }

  const signinWithEmailAndPassword = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>,
    setAuthError: (errMessage: string) => void,
  ) => {
    setSubmitting(true)
    setAuthError('')
    try {
      trackAuthEvent(AuthEventType.LOGIN, AuthEventMethod.EMAIL)
      await signInWithEmailAndPassword(values.email, values.password)
    } catch (error) {
      setAuthError(error.message)
    } finally {
      setSubmitting(false)
    }
  }

  const signupWithGoogle = async () => {
    trackAuthEvent(AuthEventType.SIGNUP, AuthEventMethod.GOOGLE)
    const authUserCredential = await startGoogleSignin()
    //@ts-ignore
    await createMCFAUser(authUserCredential.user)
  }

  const signinWithGoogle = async () => {
    trackAuthEvent(AuthEventType.LOGIN, AuthEventMethod.GOOGLE)
    await startGoogleSignin()
  }

  const startGoogleSignin = async () => {
    const browser = detect()
    return googleSignin(browser?.name.includes('instagram'))
  }

  const signupWithApple = async () => {
    trackAuthEvent(AuthEventType.SIGNUP, AuthEventMethod.APPLE)
    const authUserCredential = await startAppleSignin()
    //@ts-ignore
    await createMCFAUser(authUserCredential.user)
  }

  const signinWithApple = async () => {
    trackAuthEvent(AuthEventType.LOGIN, AuthEventMethod.APPLE)
    await startAppleSignin()
  }

  const startAppleSignin = async () => {
    const browser = detect()
    return appleSignin()
  }

  const handleForgotPassword = async (
    values: ForgotPasswordFormValues,
    { setSubmitting }: FormikHelpers<ForgotPasswordFormValues>,
    setAuthSuccess: (status: boolean) => void,
  ) => {
    setSubmitting(true)
    await sendPasswordResetEmail(values.email)
    setSubmitting(false)
    setAuthSuccess(true)
  }

  const handleResetPassword = async (
    values: ResetPasswordFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ResetPasswordFormValues>,
    resetCode: string,
    setAuthSuccess: (status: boolean) => void,
  ) => {
    setSubmitting(true)
    await confirmPasswordReset(resetCode, values.password)
    setSubmitting(false)
    setAuthSuccess(true)
    resetForm()
  }

  return {
    signupWithEmailAndPassword,
    signinWithEmailAndPassword,
    signupWithGoogle,
    signinWithGoogle,
    signupWithApple,
    signinWithApple,
    handleForgotPassword,
    handleResetPassword,
  }
}

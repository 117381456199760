import styled from 'styled-components'
import { Typography } from 'antd'

export const StreakWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  background-color: #fff;
  padding: 2rem 0 1rem;
  width: 98%;
  margin-bottom: 2rem;
  border-radius: 1rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 12px;
`
export const StreakDay = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 8px;
`
export const StreakLabel = styled.span`
  font-size: 0.8em;
  color: #666;
  font-weight: 500;
`
export const StreakDate = styled.div<{ color: string }>`
  padding: 0.5rem;
  border-radius: 50%;
  border: ${({ color }) => `3px solid ${color}`};
  color: #999;
  font-weight: 700;
  line-height: 18px;
  width: 2.5rem;
  text-align: center;
  cursor: pointer;
`
export const StreakIndicator = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#333' : '#FFF')};
`

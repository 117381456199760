import React, { FC, useContext, useEffect, useState } from 'react'
import { Avatar, Button, Layout, Menu, Popover } from 'antd'
import Icon, {
  InfoCircleOutlined,
  UserOutlined,
  AreaChartOutlined,
} from '@ant-design/icons'

import { ReactComponent as User } from '../../../assets/icons/User.svg'
import { ReactComponent as Boxes } from '../../../assets/icons/Boxes.svg'
import { ReactComponent as Calendar } from '../../../assets/icons/Calendar.svg'
import { ReactComponent as Book } from '../../../assets/icons/Book.svg'
import { ReactComponent as Brain } from '../../../assets/icons/Brain.svg'
import { ReactComponent as Timer } from '../../../assets/icons/timer.svg'
import { ReactComponent as Puzzle } from '../../../assets/icons/Puzzle.svg'
import { Link } from 'react-router-dom'
import { auth, logout } from '../../../services/FirebaseService'
import { UserStoreContext } from '../../../stores/UserStore'
import Logo from '../../Logo'
import { LayoutProps } from '../types'
import TransitionContainer from 'components/TransitionContainer'
import useWindowSize from 'hooks/useWindowSize'

import './style.scss'
import InfoModal from '../../InfoModal/InfoModal'

const { Header, Sider, Content } = Layout

const AppLayout: FC<LayoutProps> = (props: LayoutProps) => {
  const UserStore = useContext(UserStoreContext)
  const [collapsed, setCollapsed] = useState(true)
  const [helpModalVisible, setHelpModalVisible] = useState(false)
  const [avatar, setAvatar] = useState('')
  const { windowWidth } = useWindowSize()
  const handleChange = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    const currentUser = auth.currentUser
    if (currentUser?.photoURL) {
      setAvatar(currentUser.photoURL)
    }
  }, [])

  //@ts-ignore
  const layoutWidth = windowWidth * 0.95 < 1520 ? windowWidth * 0.95 : 1520

  return (
    <Layout className={props.page ? `app ${props.page}` : 'app'}>
      <Sider collapsible collapsed={collapsed} onCollapse={handleChange}>
        <Logo big={!collapsed} />
        <Menu
          style={{ marginTop: '16px' }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={props.page ? [props.page] : undefined}
        >
          <Menu.Item key="home" icon={<Icon component={Boxes} />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          {/* <Menu.Item key="calendar" icon={<Icon component={Calendar} />}>
              <Link to="/calendar">Calendar</Link>
            </Menu.Item> */}
          <Menu.Item key="library" icon={<Icon component={Book} />}>
            <Link to="/library/course">Library</Link>
          </Menu.Item>
          <Menu.Item key="recall" icon={<Icon component={Brain} />}>
            <Link to="/active-recall">Dash Cards</Link>
          </Menu.Item>
          <Menu.Item key="adaptive-quiz" icon={<Icon component={Puzzle} />}>
            <Link to="/adaptive-quiz">Adaptive Quiz</Link>
          </Menu.Item>
          <Menu.Item key="mock-exam" icon={<Icon component={Timer} />}>
            <Link to="/mock-exam">Mock Exam</Link>
          </Menu.Item>
          <Menu.Item key="analytics" icon={<AreaChartOutlined />}>
            <Link to="/analytics">Analytics</Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="profile" icon={<Icon component={User} />}>
            <Link to="/profile">Profile</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <TransitionContainer>
        <Layout style={{ minWidth: layoutWidth + 'px' }}>
          <Header>
            <h2>{props.title}</h2>
            <div>
              {/* <Input
                size="large"
                placeholder="What are you looking for?"
                prefix={<SearchOutlined />}
              /> */}

              {/* <InfoCircleOutlined
                  onClick={() => setHelpModalVisible(true)}
                  style={{ fontSize: '2rem' }}
                /> */}
              <Popover
                placement="bottomRight"
                title={
                  UserStore.profile.name
                    ? UserStore.profile.name
                    : UserStore.email
                }
                content={
                  <>
                    <Button onClick={logout}>Logout</Button>
                  </>
                }
                trigger="click"
              >
                {avatar.length > 0 ? (
                  <Avatar size="large" src={avatar} />
                ) : (
                  <Avatar size="large" icon={<UserOutlined />} />
                )}
              </Popover>
            </div>
          </Header>
          <Content>{props.children}</Content>
        </Layout>
      </TransitionContainer>
      {/* <HelpModal
          show={helpModalVisible}
          close={() => setHelpModalVisible(false)}
        /> */}
    </Layout>
  )
}

const HelpModal = ({ show, close }: { show: boolean; close: () => void }) => {
  return null
}

export default AppLayout

import React, { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { PrivateRoutesProps } from './types'

const VerifyEmailRoute: FC<PrivateRoutesProps> = (
  props: PrivateRoutesProps,
) => {
  const {
    component: Component,
    authenticated,
    account,
    profile,
    email,
    ...rest
  } = props

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        account ? (
          authenticated ? (
            account.isVerified ? (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: routeProps.location },
                }}
              />
            ) : (
              <Component
                account={account}
                profile={profile}
                email={email}
                {...routeProps}
              />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: routeProps.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  )
}

export default VerifyEmailRoute

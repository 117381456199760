import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'

import { getUserActivity } from 'helpers/request-helper'
import { getCurrentWeek } from 'helpers/utility-helper'

import * as S from './styles'

const Streak = () => {
  const weekRange = getCurrentWeek()
  const DAYS_OF_THE_WEEK = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  const { data: userActivity, isLoading } = useQuery(
    [getUserActivity.name],
    getUserActivity,
  )

  const weeklyStreak = useMemo(() => {
    if (userActivity) {
      const weeklyLogs = userActivity?.logs?.filter((log) =>
        dayjs(log.date).isAfter(weekRange[0]),
      )

      return weekRange.map((date) =>
        weeklyLogs?.filter((log) => dayjs(log.date).isSame(date, 'date')),
      )
    }
  }, [userActivity])

  const getStreakStatus = (idx: number, date: string) => {
    const status =
      weeklyStreak && weeklyStreak[idx] ? weeklyStreak[idx]?.length : 0
    if (dayjs().isBefore(date)) {
      return '#CCC'
    } else if (status < 1) {
      return '#DF4661'
    } else if (status < 3) {
      return '#FF850F'
    } else {
      return '#6AC46A'
    }
  }

  const getStreakDetails = (idx: number) => {
    if (!(weeklyStreak && weeklyStreak[idx] && weeklyStreak[idx].length > 0))
      return <p>❌ No check in</p>

    const activityMap = Array.from(
      new Set(weeklyStreak[idx].map(({ activity }) => activity)),
    )
    return activityMap.map((activity) => {
      switch (activity) {
        case 1:
          return <p>✅ Checked in</p>
        case 2:
          return <p>📚 Studied some</p>
        case 3:
          return <p>📝 Attempted a quiz</p>
        case 4:
          return <p>🗂️ Revised some</p>
        case 5:
          return <p>⏱️ Attempted a mock exam</p>
        case 6:
          return <p>📅 Finished daily</p>
        default:
          break
      }
    })
  }

  return (
    <S.StreakWeek>
      {weekRange.map((date, idx) => (
        <S.StreakDay key={idx}>
          <S.StreakLabel>{DAYS_OF_THE_WEEK[idx]}</S.StreakLabel>
          {dayjs().isAfter(date) ? (
            <Tooltip
              title={getStreakDetails(idx)}
              placement="bottom"
              overlayInnerStyle={{
                padding: '1rem 1rem 1px 1rem',
              }}
            >
              <S.StreakDate color={getStreakStatus(idx, date)}>
                {dayjs(date).date()}
              </S.StreakDate>
            </Tooltip>
          ) : (
            <S.StreakDate color={getStreakStatus(idx, date)}>
              {dayjs(date).date()}
            </S.StreakDate>
          )}

          <S.StreakIndicator isActive={dayjs().isSame(date, 'date')}>
            ⦿
          </S.StreakIndicator>
        </S.StreakDay>
      ))}
    </S.StreakWeek>
  )
}
export default Streak

import { FC } from 'react'

import * as S from './styles'

type OnboardingVideoProps = {
  isMobile: boolean
}

const OnboardingVideo: FC<OnboardingVideoProps> = ({ isMobile }) => {
  const ONBOARDING_VIDEO_URL = 'https://adilo.bigcommand.com/watch/GAnDtRJA'

  return (
    <S.VideoContainer isMobile={isMobile}>
      <S.VideoFrame
        src={ONBOARDING_VIDEO_URL}
        frameBorder="0"
        allowFullScreen
        scrolling="no"
      ></S.VideoFrame>
    </S.VideoContainer>
  )
}

export default OnboardingVideo

import { PropsWithChildren, useRef } from 'react'
import { Carousel, Col, Image, Skeleton } from 'antd'
import { ReactComponent as Arrow } from 'assets/icons/Arrow.svg'

import { Latex } from 'components'
import { MODULE_INITIALS_MAP } from 'helpers/constants-helper'
import { AppIcon } from 'assets'

import * as S from './styles'
import './style.scss'

type FlashCardProps = IFlashCardContent

type FlashCardContainerProps = PropsWithChildren<{
  module: string
  reading?: string
  los?: string
  progress?: number
}>

const FlashCardContainer = ({
  module,
  reading,
  los,
  children,
}: FlashCardContainerProps) => {
  return (
    <S.CardContainer>
      <S.ModuleText>{module}</S.ModuleText>
      {children}
      <S.CardFooter>
        <div className="card-meta"></div>
        <S.Image src={AppIcon} />
        <div className="card-tracker"></div>
      </S.CardFooter>
    </S.CardContainer>
  )
}

const FlashCardContent = ({ type, data }: FlashCardProps) => {
  const getSlideTemplate = ({ type, data }: FlashCardProps) => {
    switch (type) {
      case 'TITLE':
        return (
          <S.TitleContent>
            <S.TitleHeader>
              <S.TitleHeaderBG />
              <S.TitleHeaderText>CONCEPT SERIES - CFA L1</S.TitleHeaderText>
            </S.TitleHeader>
            <S.CardTitle>{data}</S.CardTitle>
          </S.TitleContent>
        )
      case 'EQUATION':
        const formulaData = data as IFormula
        const { formula, formulaSize, legend, legendSize } = formulaData
        return (
          <>
            <S.DefinitionHeader>FORMULA</S.DefinitionHeader>
            <S.FormulaContainer>
              <S.LatexContainer type="formula" size={formulaSize}>
                <Latex latexString={formula.replaceAll('\\\\', '\\')} />
              </S.LatexContainer>
              <S.LatexContainer type="formula" size={legendSize}>
                <Latex latexString={legend} />
              </S.LatexContainer>
            </S.FormulaContainer>
          </>
        )
      case 'DEFINITION':
        const definition = data as string[]
        return (
          <S.DefinitionContainer>
            <S.DefinitionHeader>DEFINITION</S.DefinitionHeader>
            {definition.map((item, idx) => (
              <S.CardBodyItem key={idx}>{item}</S.CardBodyItem>
            ))}
          </S.DefinitionContainer>
        )
      case 'SUMMARY':
        const summary = data as string[]
        return (
          <S.DefinitionContainer>
            <S.DefinitionHeader>SUMMARY</S.DefinitionHeader>
            {summary.map((item, idx) => (
              <S.CardBodyItem key={idx}>{item}</S.CardBodyItem>
            ))}
          </S.DefinitionContainer>
        )
      default:
        break
    }
  }

  return <S.CardContent>{getSlideTemplate({ type, data })}</S.CardContent>
}

const FlashCardDeck = ({
  cardDeck,
  onProgress,
}: {
  cardDeck: IFlashCard
  onProgress?: (progress: number) => void
}) => {
  const currentSlideIndex = useRef(0)
  const slider = useRef<any>(null)

  const contentType =
    cardDeck.content &&
    Array.isArray(cardDeck.content) &&
    cardDeck.content.length > 0
      ? 1
      : 2

  const cardDeckModule = cardDeck.module.id as keyof typeof MODULE_INITIALS_MAP
  const moduleInitial = MODULE_INITIALS_MAP[cardDeckModule]
  const slideContent = contentType === 1 ? cardDeck.content : cardDeck.slides

  const onChange = (currentSlide: number) => {
    if (currentSlide > currentSlideIndex.current) {
      currentSlideIndex.current = currentSlide
      onProgress &&
        onProgress(
          parseInt(
            (((currentSlide + 1) * 100) / slideContent.length).toFixed(0),
          ),
        )
    }
  }

  const prevSlide = () => {
    if (slider && slider.current) {
      slider.current.prev()
    }
  }

  const nextSlide = () => {
    if (slider && slider.current) {
      slider.current.next()
    }
  }

  const getCardDeck = () => {
    //@ts-ignore
    return slideContent.map((slide, idx) => {
      const { type, data } = slide as IFlashCardContent
      return (
        <FlashCardContainer key={idx} module={moduleInitial}>
          <FlashCardContent type={type} data={data} />
        </FlashCardContainer>
      )
    })
  }

  const getCardSlides = () => {
    return slideContent.map((card: any) => (
      <div key={card.id} className="card-wrapper">
        <Col span={24} className="card">
          <Image
            src={card.url}
            preview={false}
            placeholder={
              <div>
                <Skeleton active loading paragraph={{ rows: 16 }}></Skeleton>
              </div>
            }
          />
        </Col>
      </div>
    ))
  }

  return (
    <div className="flash-cards">
      <Carousel ref={slider} afterChange={onChange} dots infinite={false}>
        {contentType === 1 ? getCardDeck() : getCardSlides()}
      </Carousel>
      <Arrow className="arrow arrow-prev" onClick={prevSlide} />
      <Arrow className="arrow arrow-next" onClick={nextSlide} />
    </div>
  )
}

export default FlashCardDeck

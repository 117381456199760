import React, { useState } from 'react'
import {
  loadStripe,
  StripeElementsOptions,
  PaymentIntent,
} from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import * as Yup from 'yup'
import { message, Collapse } from 'antd'

import analytics from 'services/AnalyticsService'
import UserService from 'services/UserService'
import { ANALYTICS_EVENTS } from 'helpers/constants-helper'
import { FormInput as Input, Button } from 'components'
import CheckoutForm from './CheckoutForm'

import * as S from './styles'

type StripeFormProps = {
  onSuccess: (intent: Partial<PaymentIntent>) => void
  onFailure: (amount: number, currency: string) => void
}

const { Panel } = Collapse
const STRIPE_TEST =
  'pk_test_51LjlHCSAUQuG50onMOLoxzh1EVa58kOBIUPhPxG51tXgs2SWAoSVrxbsPFV6FcqbRvPxmz1SI6T9cotx5deBZ2eZ00qQMIVZPb'
const STRIPE_LIVE =
  'pk_live_51LjlHCSAUQuG50onKgeaX1fgpxPPfZPBso1MPWDtjGCquBAvy18oBqahpaFyVKSOuwp0MExoHOfFYiUlvdRqaiPz00ewFPxUs2'
const stripePromise = loadStripe(STRIPE_LIVE)

const StripeForm = ({ onSuccess, onFailure }: StripeFormProps) => {
  const [openPanel, setOpenPanel] = useState('1')
  const [stripeClientSecret, setStripeClientSecret] = useState<string | null>(
    null,
  )
  const [stripePaymentIntent, setStripePaymentIntent] = useState(
    {} as PaymentIntent,
  )
  const [stripeCustomer, setStripeCustomer] = useState()

  const AddressSchema = Yup.object().shape({
    street: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
  })

  const BaseAddress = {
    street: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
  } as IBillingAddress

  const stripeOptions = {
    appearance: {
      theme: 'flat',
    },
    clientSecret: stripeClientSecret,
  } as StripeElementsOptions

  const saveCustomerAddress = async (address: IBillingAddress) => {
    const { paymentIntent, customer } = await UserService.createStripeCustomer(
      address,
    )
    const { amount, currency, client_secret } = paymentIntent as PaymentIntent

    analytics.track(ANALYTICS_EVENTS.PURCHASE_INITIATED, {
      price: amount / 100,
      currency,
      mode: 'stripe',
    })

    setStripeClientSecret(client_secret)
    setStripePaymentIntent(paymentIntent)
    setStripeCustomer(customer)
    setOpenPanel('2')
  }

  const onPaymentSuccess = () => {
    const { id, amount, currency } = stripePaymentIntent
    onSuccess({
      id,
      amount,
      currency,
    })
  }

  return (
    <Collapse
      expandIconPosition="end"
      ghost
      defaultActiveKey={['1']}
      activeKey={[openPanel]}
      accordion
    >
      <Panel
        key="1"
        header={
          <div className="reading-panel reading-panel-lg">
            <div className="panel-title">Billing Address</div>
          </div>
        }
      >
        <Formik
          initialValues={BaseAddress}
          validationSchema={AddressSchema}
          onSubmit={saveCustomerAddress}
        >
          {({ touched, isValid, isSubmitting }) => (
            <Form>
              <Input name="street" placeholder="Street address" />
              <S.AddresRow>
                <Input name="city" placeholder="City" />
                <Input name="state" placeholder="State" />
              </S.AddresRow>
              <S.AddresRow>
                <Input name="country" placeholder="Country" />
                <Input name="postalCode" placeholder="Postal Code" />
              </S.AddresRow>
              <Button
                htmlType="submit"
                disabled={!isValid || !touched.street}
                loading={isSubmitting}
                style={{
                  width: '100%',
                }}
              >
                SAVE
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
      <Panel
        key="2"
        header={
          <div className="reading-panel reading-panel-lg">
            <div className="panel-title">Payment Details</div>
          </div>
        }
      >
        {stripeClientSecret && (
          <Elements options={stripeOptions} stripe={stripePromise}>
            <CheckoutForm
              clientSecret={stripeClientSecret}
              onSuccess={onPaymentSuccess}
            />
          </Elements>
        )}
      </Panel>
    </Collapse>
  )
}

export default StripeForm

import React, { useState } from 'react'
import { MathJax, MathJaxContext } from 'better-react-mathjax'

const config = {
  loader: { load: ['[tex]/html'] },
  tex: {
    packages: { '[+]': ['html'] },
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
  },
  options: {
    enableMenu: false,
  },
}

export default function BetterMathJax({
  latexString,
}: {
  latexString: string
}) {
  return (
    <MathJaxContext version={3} config={config}>
      <MathJax hideUntilTypeset={'first'}>{latexString}</MathJax>
    </MathJaxContext>
  )
}

import React, { FC } from 'react'

import { BackgroundProps } from '../types'
import { Layout, Row, Col } from 'antd'
import Logo from '../../Logo'

import './style.scss'

const { Header, Content } = Layout

const OnboardingLayout: FC<BackgroundProps> = (props: BackgroundProps) => {
  return (
    <div className="second-background">
      <div className="green-oval"></div>
      <div className="blue-oval"></div>
      <div className="yellow-oval"></div>
      <Layout className="auth main-wrapper">
        <Header>
          <Row>
            <Col span={8} lg={{ span: 6, offset: 2 }} xs={{ span: 24, offset: 2 }}>
              <Logo big />
            </Col>
          </Row>
        </Header>
        <Content>{props.children}</Content>
      </Layout>
    </div>
  )
}

export default OnboardingLayout

import React, { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { PrivateRoutesProps } from './types'

const TutorialRoute: FC<PrivateRoutesProps> = (props: PrivateRoutesProps) => {
  const { component: Component, authenticated, account, ...rest } = props
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        account ? (
          authenticated ? (
            account.isVerified ? (
              account.isFirstTime ? (
                <Component {...routeProps} />
              ) : (
                <Redirect to="/" />
              )
            ) : (
              <Redirect to="/verify-email" />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: routeProps.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  )
}

export default TutorialRoute

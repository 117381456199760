import styled from 'styled-components'

type CardContainerProps = {
  backgroundColor?: string
}

type TitleTextProps = {
  color?: string
  isTitleLong?: boolean
}

type FormulaContainerProps = {
  type?: 'formula' | 'legend'
  size?: number
}

const windowWidth = 660

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${windowWidth}px;
  min-height: ${windowWidth}px;
  background-color: #fff;
  padding: 2rem;
  position: relative;
  overflow: hidden;
`

export const ModuleContainer = styled.div`
  background-color: #1e9bd6;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12%;
  height: 18%;
  top: 0;
  right: 4%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const ModuleContainerShadow = styled.div`
  border: 2px solid #1e9bd6;
  position: absolute;
  width: 11%;
  height: 20%;
  top: -1%;
  right: 3.24%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const LatexContainer = styled.div<FormulaContainerProps>`
  font-size: ${({ size }) => (size ? size - 0.5 : 1)}rem;
  color: ${({ type }) => (type === 'formula' ? '#b4b7d4' : '#b4b7d4')};
  text-align: ${({ type }) => (type === 'formula' ? 'center' : 'left')};
`

export const FormulaContainer = styled.div<FormulaContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`

export const CardContent = styled.div`
  padding-top: 4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`

export const CardContentHeader = styled.span<TitleTextProps>`
  position: absolute;
  top: -32px;
  right: 0px;
  font-size: 2rem;
  font-family: 'Poller One';
  font-weight: 700;
  color: #a6c9ff;
  opacity: 0.25;
`

export const CardTitle = styled.span<TitleTextProps>`
  display: block;
  font-size: 3rem;
  font-family: 'Poppins';
  font-weight: 700;
  color: #a6c9ff;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const TitleHeader = styled.div`
  position: relative;
  margin-top: 4rem;
  margin-bottom: 2rem;
`
export const TitleHeaderBG = styled.div`
  position: absolute;
  background-color: #a6c9ff;
  opacity: 0.25;
  height: 100%;
  width: 100%;
  left: -2rem;
  z-index: 0;
  padding: 8px 0;
`
export const TitleHeaderText = styled.div`
  font-size: 2rem;
  font-family: 'Poppins';
  font-weight: 700;
  color: #a6c9ff;
  z-index: 100;
  padding: 8px 0;
`
export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModuleText = styled.span<TitleTextProps>`
  position: absolute;
  top: -32px;
  right: 0px;
  font-size: 96px;
  font-family: 'Poller One';
  font-weight: 700;
  color: #a6c9ff;
  opacity: 0.25;
`

export const CardBodyItem = styled.p<TitleTextProps>`
  text-align: left;
  font-family: 'Crimson Pro';
  color: #b4b7d4;
  font-size: 1.5rem;
`

export const DefinitionContainer = styled.div<FormulaContainerProps>``

export const DefinitionHeader = styled.span<TitleTextProps>`
  display: block;
  font-size: 2rem;
  font-family: 'Poppins';
  font-weight: 700;
  color: #a6c9ff;
  letter-spacing: 1px;
  margin-bottom: 1rem;
`

export const Image = styled.img`
  height: 32px;
  /* position: absolute; */
  opacity: 0.25;
`

import React, { FC } from 'react'

import { BackgroundProps } from './types'

import './style.scss'

const ThirdBackground: FC<BackgroundProps> = (props: BackgroundProps) => {
  return (
    <div className="third-background">
      <div className="green-oval"></div>
      <div className="blue-oval"></div>
      <div className="yellow-oval"></div>
      <div className="main-wrapper">{props.children}</div>
    </div>
  )
}

export default ThirdBackground

import React, { FC } from 'react'

import { ReactComponent as Logotype } from '../../assets/icons/mastercfa-icon.svg'
import { ReactComponent as LogoMotto } from '../../assets/icons/mastercfa-full-logo.svg'
import { AppIcon, AppLogo } from 'assets'

import './style.scss'

const Logo: FC<any> = ({ big }: { big: boolean }) => {
  return (
    <div className={`logo${big ? ' big' : ''}`}>
      {big ? <LogoMotto /> : <Logotype />}
      {/* {big ? (
        <img className="logo-image" src={AppLogo} />
      ) : (
        <img className="icon-image" src={AppIcon} />
      )} */}
    </div>
  )
}

export default Logo

import styled from 'styled-components'

import { Button as AntButton } from 'antd'

export const AddresRow = styled.div`
  display: flex;
  justify-content: space-between;
  input.ant-input {
    width: 48%;
  }
`

export const Button = styled(AntButton)`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  color: #fff;
  font-size: 1.12rem;
  letter-spacing: 1px;
  margin-top: 1em;
`

import { motion } from 'framer-motion'

const pageVariants = {
  initial: {
    translateX: 20,
    opacity: 0,
  },
  in: {
    translateX: 0,
    opacity: 1,
  },
  out: {
    translateX: 20,
    opacity: 0,
  },
}

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.25,
}

const Transitions = ({ children }: { children: any }) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {children}
    </motion.div>
  )
}

export default Transitions
